import { get } from "lodash";

class CustomCSV {
    static readonly EMAIL = "email";
    static readonly FAMILY_NAME = "familyName";
    static readonly GIVEN_NAME = "givenName";

    patternsMap = {
        [CustomCSV.EMAIL]: "([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+)",
        [CustomCSV.FAMILY_NAME]: "([\\w- ']+)",
        [CustomCSV.GIVEN_NAME]: "([\\w- ]+)",
    };

    separator = "[\\:\\;\\s,]+";

    buildRegExp(patternNames: string[]): RegExp {
        const pattern = patternNames
            .map((name: string) => get(this.patternsMap, name, ""))
            .join(this.separator);

        return new RegExp(pattern, "g");
    }

    parseLine(text: string, patternNames: string[] = []): string[] {
        if (!patternNames.length) {
            patternNames = [
                CustomCSV.GIVEN_NAME,
                CustomCSV.FAMILY_NAME,
                CustomCSV.EMAIL,
            ];
        }

        const regexp = this.buildRegExp(patternNames);
        const matches = regexp.exec(text);
        const values = [];

        for (let index = 1; index <= patternNames.length; index++) {
            const value = get(matches, index);
            if (value) {
                values.push(value);
            }
        }

        return values;
    }
}

export default new CustomCSV();
