var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TrainingClassFilter',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('SGraphQLLoader',{attrs:{"gql-query":_vm.gqlGetList,"gql-variables":_vm.queryVariables,"data-key":"trainingSessions.edges"},scopedSlots:_vm._u([{key:"default",fn:function({ model }){return _vm._l((_vm.transform(model)),function(trainingSessions,date){return _c('v-row',{key:date},[_c('v-col',{staticClass:"session-item",attrs:{"cols":"12"}},[_c('h4',{staticClass:"session-start-date-title"},[_c('FormattedDate',{attrs:{"date":date,"huge":""}})],1),_c('v-row',_vm._l((trainingSessions),function(trainingSession,index){return _c('v-col',{key:index,class:`session-item-${index}`,attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('STrainingClassItemCard',_vm._g(_vm._b({style:({
                    'border-left': `1rem solid ${_vm.brandColor(
                      trainingSession
                    )}`,
                  }),attrs:{"elevation":_vm.hoverable && hover ? 8 : 2,"to":_vm.to(trainingSession),"trainer":trainingSession.class.trainer || {},"training-class":trainingSession.class,"data-testid":"trainingClassCard"},on:{"click":function($event){return _vm.$emit('select', trainingSession)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card-actions',[_c('SAttendeesCountIcon',{attrs:{"current":trainingSession.seatsReservedCount,"total":trainingSession.seatsTotalCount,"data-testid":"attendeesCount","right":""}}),_c('SDaysCountIcon',{attrs:{"current":_vm.indexOf(
                            trainingSession,
                            trainingSession.class.sessions.edges
                          ),"total":trainingSession.class.sessions.edges.length,"data-testid":"daysCount","right":""}})],1)]},proxy:true}],null,true)},'STrainingClassItemCard',_vm.$attrs,false),_vm.$listeners))]}}],null,true)})],1)}),1)],1)],1)})}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }