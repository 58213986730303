import Vue from "vue";
import { Ticket } from "../graphql-types";
import { get } from "lodash";

const descriptor: Record<string, any> = {
    describeTicket(item: Ticket): string {
        return `${item.booking?.reference} - ${item.booking?.title}`;
    },
};

Vue.filter("descriptor", (value: any): string => {
    const typeName = get(value, "__typename", "");
    const action = descriptor[`describe${typeName}`];

    if (typeof action === "function") {
        return action(value);
    }

    return "Descriptor not found.";
});
