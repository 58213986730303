import Vue from "vue";

export function nameInitials(fullName: string): string {
    const allNames = fullName.trim().split(" ");
    return allNames.reduce((acc: string, curr: string, index: number) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, "");
}

Vue.filter("nameInitials", nameInitials);
