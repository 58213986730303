import GQLGetCurrentUser from "../graphql/user/query/GetCurrentUserQuery.graphql";
import { User } from "../index";
import { apolloClient } from "@/vue-apollo";

class UserService {
    async getUser(id: string): Promise<User> {
        const response = await apolloClient.query({
            query: GQLGetCurrentUser,
            variables: {
                id: "users/" + id,
            },
        });

        return response.data.currentUser;
    }
}

export default new UserService();
