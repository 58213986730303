import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PaginatedPage extends Vue {
    @Prop({ type: Number })
    readonly limit!: number;

    @Prop({ type: Number })
    readonly page!: number;

    @Prop({ type: String })
    readonly sort!: string;
}

export const paginatedPageQueryParams = (defaultObj?: Record<string, any>) => {
    return function ({ query }: Record<string, any>): Record<string, any> {
        return {
            ...defaultObj,
            ...query,
            limit: parseInt(query.limit || defaultObj?.limit || 20),
            page: parseInt(query.page || 1),
            sort: query.sort || defaultObj?.sort || "",
        };
    };
};
