import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { ApolloClient } from "apollo-client";
import store from "./store";
import router from "./router";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "ody-token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || "/graphql";
// Files URL root
export const filesRoot =
    process.env.VUE_APP_FILES_ROOT ||
    httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic query persisting with Apollo Engine
    wsEndpoint: "",
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in theow can you make it so that a component watchLoading blocks the global one?
    // httpLinkOptions property of defaultOptions.
    // link: myLink

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

export const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ///...options,
});

// Call this in the Vue app file
export function createProvider(options = {}): VueApollo {
    // Create apollo client

    // Create vue apollo provider
    return new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                loadingKey: "loading",
                // fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            if (error.message.includes("401")) {
                store.dispatch("logout");
                router.push({ name: "login" });
            } else {
                console.log(
                    "%cError",
                    "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
                    error.message
                );
            }
        },
        ...options,
    });
}

// Manually call this when user log in
export async function onLogin(
    apolloClient: ApolloClient<any>,
    token: string
): Promise<boolean> {
    if (typeof localStorage !== "undefined" && token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        if (e instanceof Error) {
            console.log(
                "%cError on cache reset (login)",
                "color: orange;",
                e.message
            );
        }
        return false;
    }
    return true;
}

// Manually call this when user log out
export async function onLogout(
    apolloClient: ApolloClient<any>
): Promise<boolean> {
    if (typeof localStorage !== "undefined") {
        localStorage.removeItem(AUTH_TOKEN);
    }
    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        if (e instanceof Error) {
            console.log(
                "%cError on cache reset (logout)",
                "color: orange;",
                e.message
            );
        }
        return false;
    }
    return true;
}
