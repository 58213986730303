var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-text',[_c('SHorizontalTable',{attrs:{"headers":_vm.headers,"items":[_vm.invoice],"no-border":""},scopedSlots:_vm._u([{key:"item-billing-address",fn:function({ item }){return [_c('SEditableField',_vm._b({attrs:{"value":item.billingAddress},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('SAddressLineLabel',{attrs:{"address":item.billingAddress}})]},proxy:true},{key:"editing",fn:function({ reset, model }){return [_c('SGraphQLMutator',{attrs:{"gql-mutation":_vm.gqlUpdateBillingAddressAndPONumber,"gql-variables":{
                input: {
                  id: _vm.invoice.id,
                  billingAddress: model.value,
                },
              }},on:{"saved":reset},scopedSlots:_vm._u([{key:"default",fn:function({ loading, mutate }){return [_c('AddressField',{attrs:{"disabled":loading,"loading":loading},model:{value:(model.value),callback:function ($$v) {_vm.$set(model, "value", $$v)},expression:"model.value"}}),_c('SConfirmDialogActions',{attrs:{"loading":loading},on:{"cancel":reset,"confirm":mutate}})]}}],null,true)})]}}],null,true)},'SEditableField',_vm.$attrs,false))]}},{key:"item-customer-type-name",fn:function({ item }){return [_c('OrganisationTypeLabel',{attrs:{"name":item.customer.type.name}})]}},{key:"item-document-date",fn:function({ item }){return [_c('SEditableField',_vm._b({attrs:{"readonly":!_vm.canWriteDocumentDate,"value":_vm._f("isoDate")(item.documentDate)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FormattedDate',{attrs:{"date":item.documentDate,"long":""}})]},proxy:true},{key:"editing",fn:function({ reset, model }){return [_c('SGraphQLMutator',{attrs:{"gql-mutation":_vm.gqlUpdateDocumentDateAndNotes,"gql-variables":{
                input: {
                  id: _vm.invoice.id,
                  documentDate: model.value,
                },
              }},on:{"saved":reset},scopedSlots:_vm._u([{key:"default",fn:function({ loading, mutate }){return [_c('SInlineDateInput',{attrs:{"disabled":loading,"label":_vm.$t('document_date'),"autofocus":""},on:{"cancel":reset,"confirm":mutate},model:{value:(model.value),callback:function ($$v) {_vm.$set(model, "value", $$v)},expression:"model.value"}})]}}],null,true)})]}}],null,true)},'SEditableField',_vm.$attrs,false))]}},{key:"item-due-date",fn:function({ item }){return [_c('SEditableField',_vm._b({attrs:{"readonly":!_vm.canWriteDueDate,"value":_vm._f("isoDate")(item.dueDate)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FormattedDate',{attrs:{"date":item.dueDate,"long":""}})]},proxy:true},{key:"editing",fn:function({ reset, model }){return [_c('SGraphQLMutator',{attrs:{"gql-mutation":_vm.gqlUpdateInvoice,"gql-variables":{
                input: {
                  id: _vm.invoice.id,
                  dueDate: model.value,
                },
              }},on:{"saved":reset},scopedSlots:_vm._u([{key:"default",fn:function({ loading, mutate }){return [_c('SInlineDateInput',{attrs:{"disabled":loading,"label":_vm.$t('due_date'),"autofocus":""},on:{"cancel":reset,"confirm":mutate},model:{value:(model.value),callback:function ($$v) {_vm.$set(model, "value", $$v)},expression:"model.value"}})]}}],null,true)})]}}],null,true)},'SEditableField',_vm.$attrs,false))]}},{key:"item-type",fn:function({ item }){return [_c('InvoiceTypeLabel',{attrs:{"name":item.type}})]}},{key:"item-notes",fn:function({ item }){return [_c('SEditableField',_vm._b({attrs:{"readonly":!_vm.canWriteNotes,"value":item.notes || ''},scopedSlots:_vm._u([{key:"editing",fn:function({ reset, model }){return [_c('SGraphQLMutator',{attrs:{"gql-mutation":_vm.gqlUpdateDocumentDateAndNotes,"gql-variables":{
                input: {
                  id: _vm.invoice.id,
                  notes: model.value,
                },
              }},on:{"saved":reset},scopedSlots:_vm._u([{key:"default",fn:function({ loading, mutate }){return [_c('STextArea',{attrs:{"disabled":loading},model:{value:(model.value),callback:function ($$v) {_vm.$set(model, "value", $$v)},expression:"model.value"}}),_c('SConfirmDialogActions',{attrs:{"loading":loading},on:{"cancel":reset,"confirm":mutate}})]}}],null,true)})]}}],null,true)},'SEditableField',_vm.$attrs,false))]}},{key:"item-po-number",fn:function({ item }){return [_c('SEditableField',_vm._b({attrs:{"value":item.poNumber},scopedSlots:_vm._u([{key:"editing",fn:function({ reset, model }){return [_c('SGraphQLMutator',{attrs:{"gql-mutation":_vm.gqlUpdateBillingAddressAndPONumber,"gql-variables":{
                input: {
                  id: _vm.invoice.id,
                  poNumber: model.value,
                },
              }},on:{"saved":reset},scopedSlots:_vm._u([{key:"default",fn:function({ loading, mutate }){return [_c('SInlineTextField',{attrs:{"loading":loading,"disabled":loading,"label":_vm.$t('po_number'),"autofocus":""},on:{"cancel":reset,"confirm":mutate},model:{value:(model.value),callback:function ($$v) {_vm.$set(model, "value", $$v)},expression:"model.value"}})]}}],null,true)})]}}],null,true)},'SEditableField',_vm.$attrs,false))]}},_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }