import Vue from "vue";
import { mapValues, values } from "lodash";

export function mapBy(items: any[], key: string): any[] {
    if (key) {
        return values(mapValues(items, key));
    }

    return items;
}

Vue.filter("mapBy", mapBy);
