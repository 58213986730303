import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import authGuard from "./guard/auth";
import { paginatedPageQueryParams } from "sx-shared-core";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        name: "home",
        path: "/",
        component: () => import("@/views/app/auth/IndexView.vue"),
        redirect: "/training-classes",
        children: [
            {
                name: "login",
                path: "login",
                component: () => import("@/views/app/auth/LoginView.vue"),
            },
            {
                name: "pre-course-feedback.show",
                path: "/pre-course-feedback/:hash",
                component: () =>
                    import(
                        "@/views/app/pre-course-feedback/PreCourseFeedbackShowView.vue"
                    ),
            },
            {
                name: "post-course-feedback.show",
                path: "/post-course-feedback/:hash",
                component: () =>
                    import(
                        "@/views/app/post-course-feedback/PostCourseFeedbackShowView.vue"
                    ),
            },
        ],
    },
    {
        path: "/app",
        component: () => import("@/views/app/Index.vue"),
        beforeEnter: authGuard,
        redirect: "/app/training-classes",
        children: [
            {
                name: "training-classes",
                path: "/training-classes",
                component: () =>
                    import(
                        "@/views/app/training-class/TrainingClassIndexView.vue"
                    ),
            },
            {
                path: "/booking",
                component: () =>
                    import("@/views/app/booking/BookingParentView.vue"),
                children: [
                    {
                        name: "booking.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "createdAt:desc",
                        }),
                        component: () =>
                            import("@/views/app/booking/BookingIndexView.vue"),
                    },
                    {
                        name: "booking.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/booking/BookingShowView.vue"),
                    },
                ],
            },
            {
                path: "/invoice",
                component: () =>
                    import("@/views/app/invoice/InvoiceParentView.vue"),
                children: [
                    {
                        name: "invoice.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "documentDate:desc",
                        }),
                        component: () =>
                            import("@/views/app/invoice/InvoiceIndexView.vue"),
                    },
                    {
                        name: "invoice.show",
                        path: ":id",
                        component: () =>
                            import("@/views/app/invoice/InvoiceShowView.vue"),
                    },
                ],
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes,
});

export default router;
