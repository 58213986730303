import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import en from "vuetify/src/locale/en";
import "@fortawesome/fontawesome-free/css/all.css";

const mq = window.matchMedia("(prefers-color-scheme: dark)");

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    lang: {
        locales: { en },
        current: "en",
    },
    theme: {
        dark: mq.matches,

        default: "light",
        disable: false,
        options: {
            cspNonce: undefined,
            customProperties: true,
            minifyTheme: undefined,
            themeCache: undefined,
        },
        themes: {
            light: {
                primary: colors.blue,
                secondary: "#304156",
                success: colors.green,
                danger: colors.red,
                warning: colors.deepOrange,
                info: colors.indigo,

                dark: "#242939",

                background: "#f2f3f8",
            },
            dark: {
                primary: colors.blue,
                secondary: "#304156",
                success: colors.green,
                danger: colors.red,
                warning: colors.deepOrange,
                info: colors.indigo,
            },
        },
    },
});
