import Vue from "vue";
import Vuex from "vuex";
import { AuthStoreState } from "@/store/modules/AuthStore";
import { ThemeStoreState } from "@/store/modules/ThemeStore";

Vue.use(Vuex);

export interface RootState {
    auth: AuthStoreState;
    theme: ThemeStoreState;
}

export default new Vuex.Store<RootState>({});
