export const items = [
    {
        icon: "mdi-school",
        roles: ["ROLE_CUSTOMER_ATTENDEE"],
        title: "nav.training_courses",
        to: { name: "training-classes" },
    },
    {
        icon: "mdi-book",
        roles: ["ROLE_CUSTOMER_BOOKER"],
        title: "nav.bookings",
        to: { name: "booking.index" },
    },
    {
        icon: "mdi-receipt-text-outline",
        roles: ["ROLE_CUSTOMER_BOOKER"],
        title: "nav.invoices",
        to: { name: "invoice.index" },
    },
];
