var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.hideFilters)?_c('v-row',[(!_vm.hideDateFilter)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":!_vm.hideTypeFilter ? 10 : 12}},[_c('v-card',{staticClass:"elevation-5 flex d-flex flex-column"},[_c('v-card-text',{staticClass:"flex"},[_c('DateRangeSelector',{attrs:{"period":_vm.period,"use-query-params":_vm.useQueryParams},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)],1):_vm._e(),(!_vm.hideTypeFilter)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"2"}},[_c('v-card',{staticClass:"elevation-5 flex d-flex flex-column"},[_c('v-card-text',[_c('STrainingClassTypeFilterButton',{attrs:{"use-query-params":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1):_vm._e()],1):_vm._e(),_c('SGraphQLLoader',_vm._b({attrs:{"gql-variables":_vm.queryParameter},scopedSlots:_vm._u([{key:"default",fn:function({ model }){return [_vm._t("default",function(){return _vm._l((_vm.transformModel(model)),function(trainingSessions,date){return _c('v-row',{key:date},[_c('v-col',{staticClass:"session-item",attrs:{"cols":"12"}},[_c('h4',{staticClass:"session-start-date-title"},[_c('FormattedDate',{attrs:{"date":date,"huge":""}})],1),_c('v-row',_vm._l((trainingSessions),function(trainingSession,index){return _c('v-col',{key:index,class:`session-item-${index}`,attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_vm._t("default",function(){return [_c('STrainingClassItemCard',_vm._g(_vm._b({style:({
                      'border-left': `1rem solid ${_vm.brandColor(
                        trainingSession
                      )}`,
                    }),attrs:{"elevation":_vm.hoverable && hover ? 8 : 2,"to":_vm.to(trainingSession),"trainer":trainingSession.class.trainer || {},"training-class":trainingSession.class,"data-testid":"trainingClassCard"},on:{"click":function($event){return _vm.$emit('select', trainingSession)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card-actions',[_c('SAttendeesCountIcon',{attrs:{"current":trainingSession.seatsReservedCount,"total":trainingSession.seatsTotalCount,"data-testid":"attendeesCount","right":""}}),_c('SDaysCountIcon',{attrs:{"current":_vm.indexOf(
                              trainingSession,
                              trainingSession.class.sessions.edges
                            ),"total":trainingSession.class.sessions.edges.length,"data-testid":"daysCount","right":""}})],1)]},proxy:true}],null,true)},'STrainingClassItemCard',_vm.$attrs,false),_vm.$listeners))]},{"trainingSession":trainingSession})]}}],null,true)})],1)}),1)],1)],1)})},{"model":model})]}}],null,true)},'SGraphQLLoader',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }