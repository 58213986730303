import { extend } from "vee-validate";
import {
    required,
    email,
    min_value,
    max_value,
    max,
    min,
    regex,
    numeric,
} from "vee-validate/dist/rules";
import i18n from "../i18n";

export default {
    install(): void {
        extend("email", email);
        extend("required", {
            ...required,
            message: i18n.t("validation.required").toString(),
        });
        extend("min_value", min_value);
        extend("max_value", max_value);
        extend("max", max);
        extend("min", min);
        extend("regex", regex);
        extend("numeric", numeric);
    },
};
