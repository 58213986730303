import UserService from "./UserService";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { User } from "../graphql-types";

const API_URL = "/";

export interface JWT {
    exp: number;
    iat: number;
    roles: Array<string>;
    ulid: string;
    username: string;
}

export default abstract class AuthService {
    login(email: string, password: string): Promise<string> {
        return axios
            .post(API_URL + "authentication_token", {
                email,
                password,
            })
            .then((response) => {
                return response.data.token;
            });
    }

    abstract validateUserRoles(user: User): boolean;

    validateToken(token: string): boolean {
        if (token == "") {
            return false;
        }
        const decoded: JWT = jwt_decode(token);

        return Date.now() < decoded.exp * 1000;
    }

    loadUser(token: string): Promise<User> {
        return UserService.getUser(this.getUserId(token));
    }

    getUserId(token: string): string {
        const decoded: Record<string, string> = jwt_decode(token);
        return decoded.ulid;
    }
}
