import { render, staticRenderFns } from "./BlameDetailMenu.vue?vue&type=template&id=6f2b7a1d"
import script from "./BlameDetailMenu.vue?vue&type=script&lang=ts"
export * from "./BlameDetailMenu.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports