import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { upperFirst } from "lodash";

@Component
export class QuestionFamilyMixin extends Vue {
    @Prop({ required: true, type: String })
    readonly id!: string;

    @Prop({ type: Object })
    readonly answer!: Record<string, any>;

    @Prop({ type: Boolean })
    readonly disabled!: boolean;

    @Prop({ required: true, type: String })
    readonly family!: string;

    @Prop({ required: true, type: Array })
    readonly headings!: QuestionHeader[];

    @Prop({ type: String })
    readonly name!: string;

    @Prop({ type: String })
    readonly rules!: string;

    @Prop({ required: true, type: String })
    readonly subtype!: string;

    @Watch("answer")
    onChangeAnswer(answer: Record<string, any>): void {
        this.value = answer.value;
        this.updateAnswer(answer);
    }

    value: any = "";

    created(): void {
        this.onChangeAnswer(this.answer);
    }

    getAnswerData(): Record<string, any> {
        return { value: this.value };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    updateAnswer(answer: Record<string, any>): void {}

    updateValue(): void {
        this.$emit("update:answer", this.getAnswerData(), this.id);
    }

    get fieldName(): string {
        return upperFirst(this.name) || "field";
    }

    get label(): string {
        return this.headings[0].heading;
    }
}

export interface ChoiceItem {
    text: string;
    value?: string;
}

export interface Question {
    answer?: Record<string, any>;
    choices?: ChoiceItem[];
    disabled?: boolean;
    family: string;
    headings: QuestionHeader[];
    id: string;
    name: string;
    others?: ChoiceItem[];
    rows?: RowItem[];
    rules?: string;
    subtype: string;
}

export interface QuestionHeader {
    heading: string;
}

export interface RowItem {
    priority?: number;
    rules?: string;
    text: string;
}
